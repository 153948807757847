#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page {
  flex-grow: 1;
}

/* Page SubTitle */
@media (max-width: 768px) {
  .page-subtitle {
    margin-left: 0;
  }
}

/* Page Options */
@media (max-width: 768px) {
  .page-options {
    margin-left: 0;
  }
}

/* Hides mobile nav search div */
.page-main .header .col.col-lg-3.ml-auto {
  display: none;
}

/* Navigation
--------------------------
*/
.nav-link {
  flex-grow: 1;
}

/* stripe stuff are in an iFrame which we cannot style. */
.StripeElement {
  padding: 0.451rem 0.75rem;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}

/* Tables
--------------------------
*/
.table-borderless th,
.table-borderless td {
  border: 0;
}

/* Text
--------------------------
*/

.display-5 {
  font-weight: 300;
  font-size: 2rem;
  line-height: 1.1;
}

.text-body {
  color: #495057 !important;
}

.text-decoration-none,
.text-decoration-none:hover {
  text-decoration: none;
}

.text-line-through {
  text-decoration: line-through;
}

/* Lists
--------------------------
*/
.step-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* line across */
.step-list::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  display: block;
  height: 1px;
  background-color: lightgray;
  content: '';
  vertical-align: middle;
}

.step-list li {
  flex-basis: 30%;
  position: relative;
  text-align: center;
}

.step-list li:not(:last-child) {
  /* margin-right: 30px; */
}

.step-list li.completed {
  color: lightgray;
}

.step-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* width: 80px; */
  /* height: 80px; */
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: #fff;
  line-height: normal;
}

/* Flex
--------------------------
*/
.flex-grow-1 {
  flex-grow: 1 !important;
}

/* Utilities
--------------------------
*/
.mr-n1 {
  margin-right: -1px !important;
}
.mt-n1 {
  margin-top: -1px !important;
}
.mb-n1 {
  margin-bottom: -1px !important;
}

